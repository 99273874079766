@import url("https://fonts.googleapis.com/css?family=Roboto:400,500,700");

.product-card {
  width: 200px;
  position: relative;
  margin: 50px auto;
  background-color: #ffffff;
}

.product-tumb {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 200px;
  padding: 30px;
}

.product-tumb img {
  max-width: 100%;
  max-height: 100%;
}

.product-details {
  border-left: 1px solid rgb(231, 231, 231);
  border-right: 1px solid rgb(231, 231, 231);
  border-bottom: 1px solid rgb(231, 231, 231);
  border-radius: 3px;
}
.shape-cart {
  display: flex;
  justify-content: space-between;
}
.service {
  color: black;
  border: 5px solid #feee00;
  border-radius: 10px 0px 10px 0px;
  display: flex;
  font-weight: 600;
  background-color: #feee00;
  -webkit-box-shadow: 0px 0px 15px -10px rgba(0, 0, 0, 0.75);
  box-shadow: 0px 0px 15px -10px rgba(0, 0, 0, 0.75);
  margin: 10px;
}
.product-catagory {
  display: block;
  font-size: 12px;
  font-weight: 700;
  text-transform: uppercase;
  color: #ccc;
  margin-bottom: 18px;
  padding: 10px;
  font-size: small;
  font-weight: 600;
}

.product-details h4 a {
  font-weight: 500;
  display: block;
  margin-bottom: 18px;
  text-transform: uppercase;
  color: #363636;
  text-decoration: none;
  transition: 0.3s;
}

.product-details h4 a:hover {
  color: #fbb72c;
}

.product-details p {
  font-size: 15px;
  line-height: 22px;
  margin-bottom: 18px;
  color: #999;
}

.product-bottom-details {
  overflow: hidden;
  border-top: none;
  padding-top: 5px;
}

.product-bottom-details div {
  float: left;
  width: 55%;
}

.product-price {
  font-size: 18px;
  color: #fbb72c;
  font-weight: 600;
}

.product-price small {
  font-size: 80%;
  font-weight: 400;
  text-decoration: line-through;
  display: inline-block;
  margin-right: 5px;
}

.product-links {
  text-align: right;
}

.product-links a {
  display: inline-block;
  margin-left: 5px;
  color: #e1e1e1;
  transition: 0.3s;
  font-size: 17px;
}

.product-links a:hover {
  color: #fbb72c;
}

.flex {
  display: flex;
  flex-wrap: wrap;
}

cart-btn {
  background-color: orangered;
}

.price-btn {
  display: flex;
}
.price {
  margin-left: 10px;
  margin-right: 45px;
  font-size: medium;
}

.offer-btn {
  text-transform: uppercase;
  font-size: 12px;
  font-weight: 700;
  background: rgb(243, 55, 55);
  color: #fff;
  color: white;
  border: 1px solid rgb(243, 55, 55);
  border-radius: 2px;
  width: 100vw;
  padding: 1px;
  text-align: center;
}

.old {
  margin-left: 10px;
}

.product-title {
  margin-left: 10px;
  font-size: 12px;
  height: 30px;
  margin-top: 5px;
}

.last-btns {
  display: flex;
  justify-content: center;
  color: rgb(0, 0, 0);
  margin-top: 5px;
  font-weight: 600;
}

.clr {
  color: #000000;
}
