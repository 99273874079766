.bgimage {
  width: 100%;
  height: 500px;
  background: url('./g.png');
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  background-attachment: fixed;
}
.bgimage h2 {
  color: white;
  text-shadow: 2px 2px #333;
}
